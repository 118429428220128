import React from "react";

import { Box, Stack, Paper, Grid2 as Grid, Chip, Typography, Link, useMediaQuery, List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { timelineOppositeContentClasses, } from '@mui/lab/TimelineOppositeContent';

import { Images, WorkExperiences, Projects } from "../assets";
import { ProjectCard, } from "../components";


const CS = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    /* styles */
    const styles = {
        headerContainer: {
            width: '100%',
            aspectRatio: { sm: '1 / 1 ', md: '970 / 250' },
            backgroundColor: theme.palette.primary.light
        },
        headerImg: {
            maxWidth: '100%',
            maxHeight: { sm: '60%', md: '100%'}, 
            objectFit: {xs: 'cover', md: 'contain'}
        },
        headerTextContainer: {
            alignContent: 'center', 
            padding: theme.spacing(2), 
            maxWidth: {xs: '100%', md: '60%', lg: '40%'}
        },
        headerText: {
            color: theme.palette.primary.contrastText,
            marginLeft: {xs: 0, md: theme.spacing(2), xl: theme.spacing(8)}, 
        },
        container: {
            margin: theme.spacing(4)
        },
        text: {
            paddingBottom: theme.spacing(0.5)
        },
        timelineContainer: {
            [`& .${timelineOppositeContentClasses.root}`]: {
                flex: { md: 0.3, lg: 0.15, xl: 0.12 }
            },
        },
        timelineContentContainer: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: '12px',
            width: { xs: "100%", md: '80%', lg: '60%' }
        },
        skillsContainer: {
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1, 
            marginTop: theme.spacing(1)
        }
    };

    /* timeline item */
    const largeScreenWorkExperiences = (
        <Timeline sx={styles.timelineContainer}>
            {WorkExperiences.map((exp) => (
                <TimelineItem>
                    <TimelineOppositeContent>
                        <Stack alignItems={"flex-end"}>
                            <Stack gap={2} alignItems={"center"}>
                                <Typography variant='body1' color="textSecondary">
                                    {exp.date}
                                </Typography>
                                <img src={exp.logo} alt={exp.company} style={{ maxHeight: 100 }} />
                            </Stack>
                        </Stack>
                    </TimelineOppositeContent>
    
                    <TimelineSeparator sx={{marginX: theme.spacing(4)}}>
                        <TimelineConnector />
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    
                    <TimelineContent>
                        <Paper elevation={3} sx={styles.timelineContentContainer}>
                            <Typography variant='h5' sx={styles.text}>{exp.position}</Typography>
                            <Typography variant='h6' gutterBottom sx={[styles.text, {fontStyle: 'italic'}]}>
                                <Link target="_blank" href={exp.companyLink} underline="hover">{exp.company}</Link>
                            </Typography>
                            {exp.description}
                            <Box sx={styles.skillsContainer}>
                                {exp.technologies.map((tech,) => (
                                    <Chip label={tech} size="medium" color='primary' />
                                ))}
                            </Box>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );

    const smallScreenWorkExperiences = (
        <List>
            {WorkExperiences.map((exp) => (
                <Paper elevation={3} sx={styles.timelineContentContainer}>
                <Typography variant='h6' sx={styles.text}>{exp.position}</Typography>
                <Typography variant='subtitle1' sx={[styles.text, {fontStyle: 'italic'}]}>
                    <Link target="_blank" href={exp.companyLink} underline="hover">{exp.company}</Link>
                </Typography>
                <Typography variant='subtitle2' color='textSecondary' gutterBottom sx={[styles.text, {fontStyle: 'italic'}]}>{exp.date}</Typography>
                {exp.description}
                <Box sx={styles.skillsContainer}>
                    {exp.technologies.map((tech,) => (
                        <Chip label={tech} size="small" color='primary' />
                    ))}
                </Box>
            </Paper>
            ))}
        </List>
    );


    /* main */
    return (
        <Box>
            {/* Header */}
            <Box sx={styles.headerContainer}>   
                <Stack direction={{xs: 'col', md: "row"}} spacing={4} sx={{height: '100%'}}>
                    <Box component='img' src={Images.profiles.cs} alt="CS" sx={styles.headerImg}/>
                    <Box sx={styles.headerTextContainer}>
                        <Typography gutterBottom sx={[styles.headerText, {fontStyle: 'italic', typography: {xs: 'h4', md: 'h3'}}]}>Technology</Typography>
                        <Typography sx={[styles.headerText, {typography: {xs: 'body1', md: 'h5'}}]}>
                            innovation not just for technology's sake, but for making the human experience even more worthwhile
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            {/* Work Experience */}
            <Box sx={styles.container}>
                <Typography variant="h4" gutterBottom color={theme.palette.primary.dark}>
                    Work Experience
                </Typography>
                {isMobile ? smallScreenWorkExperiences : largeScreenWorkExperiences}
            </Box>
            {/* Projects */}
            <Box sx={styles.container}>
                <Typography variant="h4" gutterBottom color={theme.palette.primary.dark}>
                    Projects
                </Typography>
                <Grid container spacing={2}>
                    {Projects.map((proj) => (
                        <Grid size={{xs: 12, md: 6, lg: 4}}>
                            <ProjectCard 
                                title={proj?.title}
                                date={proj?.date}
                                imageLink={proj?.imageLink}
                                summary={proj.summary}
                                githubLink={proj?.githubLink}
                                youtubeLink={proj?.videoLink}
                                technologies={proj?.technologies}
                                description={proj?.description}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
};

export default CS;