import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Paper, } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* Main */
const CompositionEntry = ({
    title, 
    year, 
    instrumentation, 
    duration, 
    movements, 
    instrumentationFull, 
    programNotes, 
    premiere,
    videoLink, 
    detailsPageLink 
}) => {
    const theme = useTheme();
    const styles = {
        card: {
            padding: theme.spacing(3), 
            width: '100%', 
            borderRadius: '16px',
            textDecoration: 'none',
            ':hover': {
                bgcolor: theme.palette.secondary.dark,
                color: 'white'
            }
        },
        title: {
            fontWeight: 'bold',
        },
        subtitle: {
            fontStyle: 'italic',
        },
        text: {
            paddingBottom: theme.spacing(0.5)
        }
    }

    return (
        <Paper 
            elevation={2}
            component={Link} 
            to={detailsPageLink} 
            state={{ 
                title: title,
                year: year,
                instrumentation: instrumentation,
                duration: duration,
                movements: movements,
                instrumentationFull: instrumentationFull,
                premiere: premiere,
                programNotes: programNotes,
                videoLink: videoLink,
            }}
            sx={styles.card}
        >
            <Typography variant="h5" sx={[styles.title, styles.text]} > {title} ({year}) </Typography>
            <Typography gutterBottom sx={[styles.subtitle, styles.text, {typography: {xs: 'body1', md: 'h6'}}]}> {instrumentation} </Typography>
            <Typography sx={[styles.text, {typography: {xs: 'body1', md: 'h6'}}]}> Duration - {duration} </Typography>
            {movements && movements.length > 0 && (
                <Box>
                    <Typography sx={[styles.text, {typography: {xs: 'body1', md: 'h6'}}]}>Movements:</Typography>
                        {movements.map((movement, index) => (
                            <Typography gutterBottom sx={[styles.text, {marginLeft: theme.spacing(2), typography: {xs: 'body2', md: 'body1'}}]}>
                                {index + 1}. {movement}
                            </Typography>
                        ))}
                </Box>
            )}
        </Paper>
    )
};

export default CompositionEntry;