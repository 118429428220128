import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import { Box, } from '@mui/material';

// Components
import { Navbar, ContactBar, Copyright, CompositionDetails } from "./components";

// Pages
import CS from "./pages/cs";
import Home from "./pages/home";
import Music from "./pages/music";
import NotFound from "./pages/notfound";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cs" element={<CS />} />
          <Route path="/music" element={<Music />} />
          <Route path="/music/:id" element={<CompositionDetails />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Box>
        <ContactBar />
      </Box>
      <Copyright />
    </>
  );
}

export default App;
