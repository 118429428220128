import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  cssVariables: true,
  palette: {
    primary: {
      main: '#31647E',
      light: '#5a8397', 
      dark: '#235168',
      contrastText: '#fff',
    },
    secondary: {
      main: '#d5c3b3',
      light: '#eedfd1',
      dark: '#95887d',
      contrastText: '#000',
    },
    background: {
      default: "#eedfd1"
    }
  },
  spacing: 8,
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#95887d',
          color: '#fff'
        },
        colorSecondary: {
          backgroundColor: '#5a8397',
          color: '#fff'
        },
      },
    },
  },
});

export default theme;