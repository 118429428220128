import React from 'react';
import { Typography } from '@mui/material';

const Copyright = () => {
  return (
    <Typography variant="body1" color="textSecondary" align="center" padding={2} margin={2}>
      © {new Date().getFullYear()} Andrew T. Lee
    </Typography>
  );
};

export default Copyright;