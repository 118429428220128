const ChamberMusic = new Map ([
    ['myTeddyBear', {
        title: 'My Teddy Bear',
        year: '2023',
        instrumentation: 'Chamber Music',
        duration: '6:50', 
        movements: null,
        instrumentationFull: [
            'Voice',
            'Flute',
            'Bb Clarinet', 
            'Violin',
            'Violincello',
            'Piano',
            'Percussion',
        ],
        programNotes: 
            '"My Teddy Bear" is a reflective composition that delves into our growth and maturation. As I embark on my journey into adulthood, I find myself in a reverie of my childhood years, reminiscing on roleplaying a wizard in an imaginary world and playing tag with friends at the park. However, “growing up” would not have happened without the support from my family, friends, and even my cherished toys. Through a “teddy bear,” this piece serves as an ode of gratitude to those who have guided me through the path of childhood. Capturing the essence of youthful innocence while also hinting at the profound realities of adulthood, I hope for this composition to evoke a nostalgic return to our earliest days, basking us in a moment of bittersweet retrospection.',
        videoLink: 'https://www.youtube.com/embed/krAaP3DaRAU?si=tCWKIj-L156gSash',
        premiere: '10/12/2023 - CCRMA Stage, Stanford CA - Kujoyama Ensemble'
    }],
    ['abandonedMansion', {
        title: 'In the Abandoned Mansion',
        year: '2022',
        instrumentation: 'Chamber Music',
        duration: '7:05', 
        movements: null,
        instrumentationFull: [
            'Bb Clarinet',
            'Violin',
            'Violincello',
            'Piano',
        ],
        programNotes: '',
        videoLink: 'https://www.youtube.com/embed/btAxMFb_LR8?si=Imw1Iwyva6B6Foso',
        premiere: '11/30/2022 - CCRMA Stage, Stanford CA - Ives Collective'
    }],
    ['hummingbird', {
        title: 'Hummingbird',
        year: '2020',
        instrumentation: 'Chamber Music',
        duration: '4:30', 
        movements: null,
        instrumentationFull: [
            'Oboe',
            'Bb Clarinet I-II',
            'Eb Alto Saxophone',
            'Bb Bass Clarinet',
            'Bassoon',
        ],
        programNotes: '',
        videoLink: 'https://www.youtube.com/embed/HEynB8kg-qA?si=6hJh1Ch6QE-UVEK8',
        premiere: ''
    }],
])    
    
export default ChamberMusic;