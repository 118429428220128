import React from "react";

import { Box, Stack, Avatar, Typography, Zoom, Slide, Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Images, } from "../assets";
import { SpanText, } from "../components";

/* Main */
const Home = () => {
    /* styles */
    const theme = useTheme();
    const styles = {
        container: {
            margin: theme.spacing(4)
        },
        headerContainer: {
            width: {xs: '100%', lg: '100%', xl: '80%'},
            margin: 'auto'
        },
        headerImg: {
            width: {xs: 200, sm: 300, md: 400}, 
            height: {xs: 200, sm: 300, md: 400}, 
        },
        headerTextContainer: {
            paddingX: {xs: theme.spacing(2), lg: theme.spacing(8), xl: theme.spacing(16)},
            paddingY: {xs: theme.spacing(4), lg: theme.spacing(2)},
        },
        specialTextContainer: {
            margin: 'auto',
            maxWidth: {xs: '95%', md: '65%', lg: '50', xl: '40%'},
            paddingY: theme.spacing(4),
        },
        textBig: {
            paddingY: theme.spacing(2),
            paddingLeft: theme.spacing(8)
        },
        textMid: {
            paddingY: theme.spacing(1.5),
            paddingLeft: theme.spacing(4)
        },
        textSmall: {
            paddingY: theme.spacing(1),
            paddingLeft: theme.spacing(0)
        },
        specialText1: {
            color: theme.palette.primary.main
        },
        specialText2: {
            color: theme.palette.secondary.dark
        }
    };

    /* animation controls */
    const [que1, setQue1] = React.useState(false);
    const [que2, setQue2] = React.useState(false);
    const [que3, setQue3] = React.useState(false);
    const [que4, setQue4] = React.useState(false);
    const [que5, setQue5] = React.useState(false);
    const [que6, setQue6] = React.useState(false);
    const [que7, setQue7] = React.useState(false);
    const [que8, setQue8] = React.useState(false);
    const delay = (ms) => (new Promise(res => setTimeout(res, ms)));
    React.useEffect(() => {
        const headerSetters = [setQue1, setQue2, setQue3, setQue4];
        const bioSetters = [setQue5, setQue6, setQue7, setQue8];
        const animate = async () => {
            // Animate header queues
            for (const setter of headerSetters) {
                await delay(600);
                setter(true);
            }
            
            // Animate bio queues
            for (const setter of bioSetters) {
                await delay(200);
                setter(true);
            }
        };
        animate();
    }, []);

    /* main */
    return (
        <Box>
            {/* Header */}
            <Box sx={styles.container}>
                <Stack container direction={{xs: 'col', lg: 'row'}} spacing={4} alignItems="center" sx={styles.headerContainer}>
                        <Fade in={que1} direction="right" timeout={500}>
                            <Avatar
                                alt="Profile Image"
                                src={Images.profiles.home}
                                sx={styles.headerImg}
                            />
                        </Fade>
                        <Box sx={styles.headerTextContainer}>
                            <Slide in={que2} direction="left" timeout={500}>
                                <Typography gutterBottom color={theme.palette.primary.main} sx={{typography: {xs: 'h4', md: 'h2'}}}>
                                    Andrew T. Lee
                                </Typography>
                            </Slide>
                            <Slide in={que2} direction="left" timeout={500}>
                                <Typography gutterBottom color={theme.palette.primary.light} sx={{fontStyle: 'italic', typography: {xs: 'h6', md: 'h4'}}}>
                                    Music Composer & Software Engineer 
                                </Typography>
                            </Slide>
                            <Zoom in={que3} timeout={500}>
                                <Typography color='textSecondary' gutterBottom sx={{typography: {xs: 'body1', md: 'h5'}}}>
                                    M.S. in Computer Science @ Stanford
                                </Typography>
                            </Zoom>
                            <Zoom in={que3} timeout={500}>
                                <Typography color='textSecondary' gutterBottom sx={{typography: {xs: 'body1', md: 'h5'}}}>
                                    B.A.S. in Music & Computer Science @ Stanford
                                </Typography>
                            </Zoom>
                        </Box>
                </Stack>
            </Box>
            {/* Bio */}
            <Box sx={styles.specialTextContainer}>
                <Slide in={que4} direction="right" timeout={500}>
                    <Typography gutterBottom sx={[styles.textSmall, {typography: {xs: 'body1', md: 'h5'}}]}>
                        Create technologies that {SpanText({text:"expand", style: styles.specialText1})} human creativity          
                    </Typography>
                </Slide>
                <Slide in={que5} direction="left" timeout={500}>
                    <Typography gutterBottom sx={[styles.textMid, {typography: {xs: 'h6', md: 'h4'}}]}>
                        Share new {SpanText({text:"perspectives", style: styles.specialText2})} through music 
                    </Typography>
                </Slide>
                <Slide in={que6} direction="right" timeout={500}>
                    <Typography gutterBottom sx={[styles.textSmall, {typography: {xs: 'body2', md: 'h6'}}]}>
                        Develop tools to {SpanText({text:"learn", style: styles.specialText1})} more about the world
                    </Typography>
                </Slide>
                <Slide in={que7} direction="left" timeout={500}>
                    <Typography gutterBottom sx={[styles.textMid, {typography: {xs: 'body1', md: 'h5'}}]}>
                        Explore {SpanText({text:"new techniques", style: styles.specialText2})} to make art 
                    </Typography>
                </Slide>
                <Slide in={que8} direction="right" timeout={500}>
                    <Typography gutterBottom sx={[styles.textBig, {typography: {xs: 'h6', md: 'h4'}}]}>
                        Design {SpanText({text:"technologies", style: styles.specialText1})} that enhance {SpanText({text:"humanities", style: styles.specialText2})}
                    </Typography>
                </Slide>
            </Box>
        </Box>
    )
};

export default Home;