import { Typography, Link } from '@mui/material';
import Images from '../Images';
import ExtLinks from '../ExtLinks';

const WorkExperiences = [
    {
      date: 'Jun 2024 - Sep 2024',
      position: 'Software Engineering Intern',
      company: 'Believer Entertainment',
      companyLink: ExtLinks.cs.believer,
      logo: Images.logos.believer,
      location: 'Remote',
      technologies: ['C++', 'Unreal Engine', 'Systems Design', 'Monday Dev'],
      description: 
        <Typography variant="body1" gutterBottom color="textSecondary">
            Developed a custom C++ audio subsystem for an open-world co-op RPG, enabling audio team to migrate to different 
            audio engines with minimal rework. Collaborated with gameplay designers to develope core ability frameworks for 
            object placement, freezing mechanics, and forced character movement, providing foundational tools to create complex 
            skill sets. 
        </Typography>
    },
    {
      date: 'Jun 2023 - Sep 2023',
      position: 'Full Stack Development Intern',
      company: 'Center for Spatial and Textual Analysis',
      companyLink: ExtLinks.cs.cesta,
      logo: Images.logos.cesta,
      location: 'Stanford, CA',
      technologies: ['JavaScript', 'HTML/CSS', 'PHP', 'SQL', 'Web Scraping'],
      description: 
        <Typography variant="body1" gutterBottom color="textSecondary">
            Pioneered a language-learning web application 
            (<Link target="_blank" href={ExtLinks.cs.svt}>syriacverbtutorial.org</Link>) 
            for Syriac learners to practice with its complex verb conjugation system. Designed a customizable question 
            generation system and optimized MySQL queries, reducing execution time by 30%. This work was presented 
            in <Link target="_blank" href={ExtLinks.cs.urtc}>MIT's 2023 URTC</Link> and <Link target="_blank" href={ExtLinks.cs.surps}>Stanford's 2023 SURPS</Link>.
        </Typography>
    }
];

const Projects = [
    {
        title: 'Analyzing Weather Impacts on NYC Bike Rentals and Accidents',
        date: '2024',
        imageLink: Images.cs.nycBikes,
        summary: 'How do weather conditions influence cycling behavior and safety in New York City? This study analyzes relationships between rainfall, wind, and temperature with bike rental durations and accident rates.',
        technologies: ['SQL', 'Google BigQuery', ],
        description:
            <Typography variant='body1' gutterBottom component="pre" sx={{ whiteSpace: 'pre-line', }}>
                {`This analysis uncovered nuanced relationships between weather parameters and cycling. Rainfall intensity minimally impacts bike activity but increases accident unpredictability, while wind slightly decreases rental durations and accident rates. Temperature shows a strong positive correlation with both variables, indicating cyclists are sensitive to thermal extremes.
            
                Using data from NYC's CitiBike program and local weather records, the study employed linear and random forest regression models to explore predictive patterns. The findings offer valuable insights for urban planners and policymakers, highlighting the need for adaptive cycling infrastructure and safety strategies that account for diverse weather conditions.`}
            </Typography>
    },
    {
        title: 'LLMs with Low-Resource Translation: Syriac→English Case Study',
        date: '2024',
        imageLink: Images.cs.syriacTranslation,
        githubLink: 'https://github.com/Adlee238/syriac-to-english',
        summary: `LLMs perform very well with highly-digitized languages, but what about low-resource languages?
                This project adds on to this literature by evaluating LLMs’ performances on Syriac-to-English translation.`,
        technologies: ['Python', 'PyTorch', 'Hugging Face', 'Google Cloud Platform', 'NLP', 'AI'],
        description:
            <Typography variant='body1' gutterBottom component="pre" sx={{ whiteSpace: 'pre-line', }}>
                {`This project explored sy→en translation using LSTM NMT, Transformer NMT, fine-tuned T5, fine-tuned GPT, and LSTM NMT with back-translation (BT). For the dataset, I used the Bible, which is the only publicly available parallel corpus for this task.
                
                In this study, LSTM NMT performed the best amongst the 5 models on this task, achieving the highest METEOR (43.42%) and BERT-Score (35.26%), outperforming the current state-of-the-art, Syriac.IO, which achieved 12.37% METEOR and 0.30% BERT-Score. Qualitatively, the LSTM NMT model’s generations also appear the most semantically accurate.
                
                Transformer-based models performed poorly, with major repetition issues. Fine-tuned GPT delivered coherent translations, but they did not align with the semantics of the reference sentences. BT was ineffective in this study, making NMT even perform slightly worse at times. Future research could investigate using different BTs and monolingual corpora that better align with the content in the parallel corpus.
               `}
            </Typography>
    },
    {
        title: 'DuetComposer',
        date: '2023',
        imageLink: Images.cs.duetComposer,
        githubLink: 'https://github.com/Adlee238/duetComposer',
        videoLink: 'https://youtu.be/xmSzn5ICXb4?si=nnEObHDFULhSqoey',
        summary: `When considering AI music generation, one might find a human-AI collaborative performance more interesting.
                This program provides the oppurtunity for a duet improvisation between a human musician and a music AI.`,
        technologies: ['ChucK', 'Audio Processing', 'FFTs', 'AI'],
        description:
            <Typography variant='body1' gutterBottom component="pre" sx={{ whiteSpace: 'pre-line', }}>
                {`DuetComposer is an automated music generator that employs k-Nearest Neighbors (kNN) on real-time spectral audio data to generate classical music harmonies. Using the Audio Mosaic framework by professor Ge Wang and assistant Yikai Li as a template, I trained a generative model on a small collection of spectral audio datasets of classical triadic chord progressions. 
                
                To "compose music" with this AI, the model asks users to first specify meter, tempo, and number of phrases per each session. The model then proceeds with a a turn-based compositional algorithm. It first listens to real-time audio input played by the user. Then, the model uses kNN retrieval to mimic the user's performance, thus "composing" its own tune. The process continues on in a back-and-forth manner, ultimately creating an improvised "duet" between a human and a computer.`}
            </Typography>
    },
];

export { WorkExperiences, Projects };