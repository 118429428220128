import React from "react";

import { Stack, IconButton } from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import { LinkedIn, Email, YouTube, GitHub } from '@mui/icons-material';
import { ExtLinks, } from "../assets";

const ContactBar = () => {
    const theme = useTheme();
    const styles = {
        container: {
            flexWrap: 'wrap', 
            justifyContent: 'center',
            alignItems: 'center',
            margin: theme.spacing(2)
        },
        button: {
            paddingX: theme.spacing(2), 
            paddingY: theme.spacing(1), 
            marginX: theme.spacing(1), 
            ':hover': {
                bgcolor: theme.palette.primary.dark,
            }
        }
    }

    return (
        <Stack 
            spacing={{ xs: 2.5, md: 3, lg: 4 }}
            direction="row"
            useFlexGap
            sx={styles.container}
        >
            <IconButton target="_blank" href={ExtLinks.contacts.linkedin}>
                <LinkedIn fontSize='large' />
            </IconButton>
            <IconButton target="_blank" href={`mailto:${ExtLinks.contacts.email}`}>
                <Email fontSize='large' />
            </IconButton>
            <IconButton target="_blank" href={ExtLinks.contacts.github}>
                <GitHub fontSize='large' />
            </IconButton>
            <IconButton target="_blank" href={ExtLinks.contacts.youtube}>
                <YouTube fontSize='large' />
            </IconButton>
        </Stack>
    )
};

export default ContactBar;
