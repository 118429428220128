import React from 'react';
import { Box } from '@mui/material';

const Copyright = ({text, style}) => {
  return (
    <Box component="span" sx={style}>
        {text}
    </Box>
  );
};

export default Copyright;