const Images = {
  profiles: {
    home: require("./homeProfile.jpg"),
    music: require("./musicProfile.jpg"),
    cs: require("./csProfile.jpg"),
  },
  logos: {
    cesta: require("./cesta.png"),
    believer: require("./believer.png"),
  },
  cs: {
    syriacTranslation: require("./syriacText.jpg"),
    nycBikes: require("./nyc.jpg"),
    duetComposer: require("./duetComposer.png"),
  }
};

export default Images;