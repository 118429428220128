const ExtLinks = {
    contacts: {
      linkedin: 'https://www.linkedin.com/in/andrew-lee-328b24231/',
      instagram: 'https://www.instagram.com/adlee238/',
      email: 'adtlee@cs.stanford.edu',
      youtube: 'https://www.youtube.com/@adtlee',
      github: 'https://github.com/Adlee238'
    },
    cs: {
      believer: 'https://believer.gg',
      cesta: 'https://cesta.stanford.edu',
      svt: 'https://syriacverbtutorial.org',
      urtc: 'https://urtc.mit.edu',
      surps: 'https://undergradresearch.stanford.edu/share/surps-asurps',
    }
  };
  
export default ExtLinks;