import * as React from 'react';

import { Box, Card, CardContent, CardActions, CardMedia, Collapse, Chip, IconButton, Typography, } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { ExpandMore as ExpandMoreIcon, YouTube, GitHub } from '@mui/icons-material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export default function ProjectCard({title, imageLink, summary, githubLink, youtubeLink, technologies, description}) {
  /* expand functionality */ 
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  /* styles */
  const theme = useTheme();
  const styles = {
    container: {
      padding: theme.spacing(2), 
      margin: {md: theme.spacing(2)}, 
      borderRadius: '12px',
    },
    skillsContainer: {
      display: 'flex', 
      flexWrap: 'wrap', 
      gap: 1, 
      marginTop: theme.spacing(1)
    }
  }

  /* main */
  return (
    <Card sx={styles.container}>
        <CardMedia
            component="img"
            image={imageLink}
            sx={{height: {xs: 150, md: 200, lg: 300}}}
        />
        <CardContent>
            <Typography sx={{typography: {xs: 'h6', md: 'h5'}}}>{title}</Typography>
            <Typography color='textSecondary' sx={{typography: {xs: 'subtitle2', md: 'body1'}}}>{summary}</Typography>
        </CardContent>
        <CardActions disableSpacing>
            {githubLink && (
              <IconButton target="_blank" href={githubLink} sx={{marginRight: theme.spacing(1)}}>
                <GitHub fontSize='large' color="primary" />
              </IconButton>
            )}
            {youtubeLink && (
              <IconButton target="_blank" href={youtubeLink} sx={{marginRight: theme.spacing(1)}}>
                <YouTube fontSize='large' color="primary" />
              </IconButton>
            )}
            
            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMoreIcon />
            </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
                {description}
                <Box sx={styles.skillsContainer}>
                {technologies.map((tech,) => (
                    <Chip label={tech} size="small" color='primary'/>
                ))}
            </Box>
            </CardContent>
        </Collapse>
    </Card>
  );
}