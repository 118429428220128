import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { Box, Stack, Typography, Button, IconButton, Collapse, CircularProgress } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { ArrowBackIosNew, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import { Compositions } from "../assets";

/* Helpers */
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

const VideoPlayer = (link, title) => {
    return (
        <Box
            sx={{
                position: 'relative',
                paddingBottom: '56.25%', // 16:9 aspect ratio
                height: 0,
                maxWidth: '100%',
                overflow: 'hidden',
                borderRadius: '16px',
            }}
        >
            <iframe 
                src={link}
                title={title}
                frameborder="0" 
                allow="fullscreen;" 
                allowfullscreen
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '16px',
                }}
            />
            
        </Box>
    );
};

const CompositionDetails = () => {
    /* Variables */
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const [compositionData, setCompositionData] = React.useState(null);
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    /* Styles */
    const styles = {
        container: {
            padding: theme.spacing(4),
        },
        backButton: {
            paddingX: theme.spacing(2), 
            paddingY: theme.spacing(1),
            marginBottom: theme.spacing(4), 
        },
        text: {
            paddingBottom: theme.spacing(2)
        },
        title: {
            fontWeight: 'bold',
            color: theme.palette.primary.dark
        },
        subtitle: {
            fontStyle: 'italic',
        },
        descriptionText: {
            marginLeft: theme.spacing(4)
        },
        recordingContainer: {
            width: {xs: '100%', md: '80%', lg: '60%'},
            marginX: 'auto',
            marginY: theme.spacing(4),
        }
    }

    /* Helpers */
    // Expand More functionality
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // Fetch details for the compostion
    React.useEffect(() => {
        setLoading(true);
        if (location.state) {
            // If data is passed through state, use it
            setCompositionData(location.state);
        } else if (id) {
            // If no state, search for the composition
            let found = false;
            for (const category of Object.values(Compositions)) {
                if (category instanceof Map && category.has(id)) {
                    setCompositionData(category.get(id));
                    found = true;
                    break;
                }
            }
            if (!found) {
                // Handle case when composition is not found
                navigate('/notfound');
            }
        }
        setLoading(false);
    }, [location, id, navigate]);

    /* Main */
    // Return error page if no details sent.
    if (!compositionData || loading) {
        return <CircularProgress />; 
    }
    const { title, year, instrumentation, duration, movements, instrumentationFull, programNotes, premiere, videoLink } = compositionData;
    return (
      <Box sx={styles.container}>
        <Button 
            variant="contained"
            onClick={() => {navigate('/music')}} 
            sx={styles.backButton}
        >
            <ArrowBackIosNew fontSize="inherit"/>
            <Typography variant="h6" sx={{marginLeft: theme.spacing(1)}}>back</Typography>
        </Button>

        {/* Main Info */}
        <Typography sx={[styles.title, styles.text, {typography: {xs: 'h4', md: 'h3'}}]}> {title} ({year}) </Typography>
        <Typography color='textSecondary' gutterBottom sx={[styles.subtitle, styles.text, {typography: {xs: 'h5', md: 'h4'}}]}> {instrumentation} </Typography>
        <Typography sx={[styles.text, {typography: {xs: 'h6', md: 'h5'}}]}>
            Duration - <span style={{fontStyle: 'italic'}}>{duration}</span>
        </Typography>

        {/* Movements */}
        {(movements && movements.length > 0) && (
            <Box>
                <Typography sx={[styles.text, {typography: {xs: 'h6', md: 'h5'}}]}>Movements:</Typography>
                    {movements.map((movement, index) => (
                        <Typography variant="body1" color='textSecondary' sx={[styles.descriptionText, styles.text]}>
                            {index + 1}. {movement}
                        </Typography>
                    ))}
            </Box>
        )}
        {/* Instrumentation */}
        {(instrumentationFull && instrumentationFull.length > 0) && (
            <Box>
                <Stack direction={'row'} sx={{ marginBottom: theme.spacing(2), }}>
                    <Box sx={{ alignContent: 'center' }}>
                        <Typography sx={{typography: {xs: 'h6', md: 'h5'}}}>Instrumentation</Typography>
                    </Box>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Stack>
                
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {instrumentationFull.map((instrument, ) => (
                        <Typography variant="body1" color='textSecondary' sx={[styles.descriptionText, styles.text]}> 
                            {instrument}
                        </Typography>
                    ))}
                </Collapse>
            </Box>
        )}

        {/* Notes */}
        {programNotes && (
            <Box>
                <Typography sx={[styles.text, {typography: {xs: 'h6', md: 'h5'}}]}>Notes:</Typography>
                <Typography variant="body1" component="pre" color='textSecondary' gutterBottom sx={[styles.descriptionText, styles.text, { whiteSpace: 'pre-line', }]}>
                    {programNotes}
                </Typography>
            </Box>
        )}
        
        {/* Premiere Info */}
        {premiere && (
            <Typography gutterBottom sx={[styles.text, {typography: {xs: 'h6', md: 'h5'}}]}>
                Premiere: <span style={{fontStyle: 'italic'}}>{premiere}</span>
            </Typography>
        )}

        {/* Recording */}
        <Box sx={styles.recordingContainer}>
            {VideoPlayer(videoLink, title)}
        </Box>
      </Box>
    );
};

export default CompositionDetails;