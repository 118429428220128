import React from 'react';
import { NavLink } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, Box, useMediaQuery, IconButton, List, ListItem, Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';


const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  /* Styles */
  const styles = {
    barContainer: {
      padding: theme.spacing(2), 
      width: '100%'
    },
    title: {
      typography: { xs: 'h6', sm: 'h5', md: 'h4' }
    },
    button: {
      color: theme.palette.primary.contrastText,
      paddingX: theme.spacing(2), 
      paddingY: theme.spacing(1), 
      marginX: theme.spacing(1), 
      ':hover': {
        bgcolor: theme.palette.primary.dark,
      }
    },
    drawerContainer: {
      backgroundColor: theme.palette.primary.light,
      height: '100%'
    },
  }

  /* nav options */
  const navItems = [
    { text: 'Home', path: '/' },
    { text: 'Music', path: '/music' },
    { text: 'Computer Science', path: '/cs' },
  ];
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // small screen
  const smallScreenBarOptions = (
    <>
      <IconButton onClick={handleDrawerToggle}>
        <MenuIcon fontSize='large' htmlColor={theme.palette.primary.contrastText} />
      </IconButton>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true, }}
      >
        <Box component={List} onClick={handleDrawerToggle} sx={styles.drawerContainer}>
          {navItems.map((item) => (
            <ListItem key={item.text}>
              <Button component={NavLink} to={item.path} onClick={handleDrawerToggle} sx={styles.button}>
                <Typography variant="h6">{item.text}</Typography>
              </Button>
            </ListItem>
          ))}
        </Box>
      </Drawer>
    </>
  );
  // large screen
  const largeScreenBarOptions = (
    <>
      {navItems.map((item) => (
        <Button key={item.text} color="inherit" component={NavLink} to={item.path} sx={styles.button}>
          <Typography variant="h5">
            {item.text}
          </Typography> 
        </Button>
      ))}
    </>
  );

  /* main */
  return (
    <AppBar position="sticky" sx={styles.barContainer}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Button component={NavLink} to="/" sx={styles.button}>
            <Typography sx={styles.title}>
              ANDREW T. LEE
            </Typography> 
          </Button>
        </Box>
        {isMobile ? smallScreenBarOptions : largeScreenBarOptions}
      </Toolbar>
    </AppBar>
  )
};

export default Navbar;