import React from "react";

import { Box, Stack, Typography, Tabs, Tab, List, ListItem, Divider, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { Images, Compositions } from "../assets";
import { CompositionEntry, } from "../components";


const Music = () => {
    /* composition tab */
    const [activeTab, setActiveTab] = React.useState('orch');
    React.useEffect(() => {
        if (localStorage.getItem("activeTab")) {
            setActiveTab(localStorage.getItem("activeTab"));
        }
    }, []);
    const changeactiveTab = (_, newValue) => {
        setActiveTab(newValue);
        localStorage.setItem("activeTab", newValue);
    }; 

    /* styles */
    const theme = useTheme();
    const styles = {
        headerContainer: {
            width: '100%',
            aspectRatio: { sm: '1 / 1 ', md: '970 / 250' },
            backgroundColor: theme.palette.primary.light
        },
        headerImg: {
            maxWidth: '100%',
            maxHeight: { xs: '60%', md: '100%'}, 
            objectFit: {xs: 'cover', md: 'contain'}
        },
        headerTextContainer: {
            alignContent: 'center', 
            padding: theme.spacing(2), 
            maxWidth: {xs: '100%', md: '60%', lg: '40%'}
        },
        headerText: {
            color: theme.palette.primary.contrastText,
            marginLeft: {xs: 0, md: theme.spacing(2), xl: theme.spacing(8)}, 
        },
        container: {
            margin: theme.spacing(4)
        },
        tabBar: {
            marginTop: theme.spacing(2),
            borderBottom: 2, 
            borderColor: 'divider',
        },
        tabBarElem: {
            marginX: {md: theme.spacing(2)},
        },
        compListItem: {
            width: {xs: '100%', md: '50%', lg: '30%'},
            padding: 0,
            marginBottom: theme.spacing(4),
        }
    }

    /* composition list */
    const CompositionList = (compositions) => {
        const listLength = compositions.size;
        let i = 0;
        return (
            <List>
                {Array.from(compositions.entries()).map(([k, v]) => {
                    i += 1;
                    return (
                    <>
                        <ListItem sx={styles.compListItem} key={k}>
                            <CompositionEntry 
                                title={v.title}
                                year={v.year}
                                instrumentation={v.instrumentation}
                                duration={v.duration}
                                movements={v.movements}
                                instrumentationFull={v.instrumentationFull}
                                programNotes={v.programNotes}
                                premiere={v.premiere}
                                videoLink={v.videoLink}
                                detailsPageLink={`/music/${k}`}
                            />   
                        </ListItem>
                        {i < listLength && <Divider sx={styles.compListItem}/>}
                    </>
                    )}
                )}
            </List>
        )
    }

    /* main */
    return (
        <Box>
            {/* Header */}
            <Box sx={styles.headerContainer}>   
                <Stack direction={{xs: 'col', md: "row"}} spacing={4} sx={{height: '100%'}}>
                    <Box component='img' src={Images.profiles.music} alt="Music" sx={styles.headerImg}/>
                    <Box sx={styles.headerTextContainer}>
                        <Typography gutterBottom sx={[styles.headerText, {fontStyle: 'italic', typography: {xs: 'h4', md: 'h3'}}]}>Music</Typography>
                        <Typography sx={[styles.headerText, {typography: {xs: 'body1', md: 'h5'}}]}>
                            a chance to express emotions that words cannot... 
                            a diary to capture moments of life...
                            a gateway to lands where stories breathe and dreams take flight
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            {/* Compositions */}
            <Box sx={styles.container}>
                <Typography variant="h4" gutterBottom color={theme.palette.primary.dark}>
                    Compositions
                </Typography>
                <TabContext value={activeTab}>
                    <Box sx={styles.tabBar}>
                        <TabList onChange={changeactiveTab} variant="scrollable" scrollButtons="auto">
                            <Tab label="Orchestra" value="orch" sx={[styles.tabBarElem, {marginLeft: 0}]} />
                            <Tab label="Chamber Music" value="chamber" sx={styles.tabBarElem} />
                            <Tab label="Electroacoustic" value="ea" sx={styles.tabBarElem} />
                        </TabList>
                    </Box>
                    <TabPanel value="orch">
                        {CompositionList(Compositions.orchestral)}
                    </TabPanel>
                    <TabPanel value="chamber">
                        {CompositionList(Compositions.chamber)}
                    </TabPanel>
                    <TabPanel value="ea">
                        {CompositionList(Compositions.ea)}
                    </TabPanel>
                </TabContext>

                
            </Box>
        </Box>
    )
};

export default Music;