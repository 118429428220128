const Electroacoustic = new Map ([
    ['anAirpodStory', {
        title: 'An AirPod Story',
        year: '2024',
        instrumentation: 'Electroacoustic',
        duration: '4:30', 
        movements: null,
        instrumentationFull: ['Piano', 'Celeste', 'Voice effects', 'Background chatter', ],
        programNotes: 'Noise-canceling headphones are a remarkable invention. They allow me to tune out the world and immerse myself in my own space. With them, I can focus, escape, and filter out the distractions around me. But during winter quarter last year, I lost one of my AirPods, leaving me without them for a while. At first, I was incredibly frustrated with this loss. However, over time, I began to notice the sounds I had long tuned out—the hum of conversation in a coffee shop, the rhythmic pattern of rain against my window, the soft whir of bike tires as they passed me on my walk back to the dorm. "An AirPod Story" is a reflection on this experience. It follows a protagonist working in the familiar scene of Tressider Union, sealed off from the world with noise-canceling AirPods. But as he drifts into a dreamscape of emptiness and silence, he begins to realize that the noise he once tried to escape is not just sound—it is life itself.',
        videoLink: 'https://www.youtube.com/embed/ExEzVy2o_cM?si=mQ82fkhqedu5LR7S',
        premiere: ''
    }],
    ['spatialSpace', {
        title: 'Spatial Space',
        year: '2023',
        instrumentation: 'Electroacoustic',
        duration: '2:20', 
        movements: null,
        instrumentationFull: ['Pulse Oscillator', 'Crystal Choirs', 'Banded Waveguides', 'Sine-wave Oscillators'],
        programNotes: 'Thinking with spatialization in mind, I thought writing a piece about "space" would be pretty ironic. Spatial Space therefore aims to capture the soundscape of space, while also spatializing this magestic realm. Listen with headphones on.',
        videoLink: 'https://www.youtube.com/embed/eGp5VZKusTo?si=0jr3XpKvadIL1kKd',
        premiere: ''
    }],
    ['winds', {
        title: 'Winds (風)',
        year: '2019',
        instrumentation: 'Electroacoustic',
        duration: '5:20', 
        movements: null,
        instrumentationFull: ['Wooden Flute', 'Oboe', 'Xylophone', 'Xylomarimba', 'Marimba', 'Vibraphone', 'Strings', 'Piano', 'Wind Chimes', 'Nature Sound Effects'],
        programNotes: 'I visited San Diego\'s Old Town several years ago and found myself inside this wind chime shop. Mesmerized by all the different wind chime sounds, I felt inspired to write a piece about it, so I composed Winds.',
        videoLink: 'https://www.youtube.com/embed/ndfgAVsP9nc?si=_q53jqlkZxP12qem',
        premiere: ''
    }],
])
    
export default Electroacoustic;