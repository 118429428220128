const Orchestral = new Map([
    ['fairytale', {
        title: 'Fairytale',
        year: '2024',
        instrumentation: 'Wind Orchestra',
        duration: '29:30',
        movements: [
            'Kingdom of Wonder (7:30)',
            'Fairy Lake (7:45)',
            'Midnight Masquerade (6:00)',
            'War\'s Triumph (8:15)'
        ],
        instrumentationFull: [
            'C Piccolo',
            'Flute I-II',
            'Oboe I-II',
            'English Horn',
            'Bassoon I-II',
            'B-flat Soprano Clarinet I-II-III',
            'B-flat Bass Clarinet',
            'E-flat Alto Saxophone I-II',
            'B-flat Tenor Saxophone',
            'E-flat Baritone Saxophone',
            'B-flat Trumpet I-II-III',
            'Horn in F I-II-III-IV',
            'Trombone I-II',
            'Bass Trombone',
            'Euphonium',
            'Tuba',
            'Contrabass',
            'Timpani',
            'Percussion I-II-III (including various percussion instruments)'
        ],
        programNotes: 
            `Inspired by the delicate and whimsical music from Studio Ghibli films and Pixar movies, "Fairytale" is a musical exploration of "magic," inviting the listener to a world of wonder and imagination.

            The piece unfolds across four distinct movements, each depicting a scene in this fantastical journey:
            1. Kingdom of Wonder: The opening movement invites the listener into a grand, magical kingdom, evoking its majesty and the excitement of discovery.
            2. Fairy Lake: A serene and mysterious lake serves as the backdrop for playful encounters with fairies, combining tranquility with a touch of enchantment.
            3. Midnight Masquerade: Set in a castle's ballroom, this movement captures the elegance and intrigue of a masked dance, where playful melodies intertwine with an air of mystery.
            4. War's Triumph: The finale depicts an epic battle between kingdoms, blending the chaos and intensity of conflict with the triumphant resolution of victory.

            Originally conceived as a project for the Stanford Wind Symphony, Fairytale was written during 2022–2023 through composition lessons with Professor Giancarlo Aquilanti. This piece represents not only a journey of storytelling but also one of growth, collaboration, and discovery.`,
        videoLink: 'https://www.youtube.com/embed/K4QtbnwS-Hg?si=bemR3GkF4ZCjNMyX',
        premiere: '11/16/2024 - Bing Concert Hall, Stanford CA - Stanford Wind Symphony',
    }],
    ['liu', {
        title: 'Liú (流)',
        year: '2022',
        instrumentation: 'Orchestra',
        duration: '5:30', 
        movements: null,
        instrumentationFull: [
            'C Piccolo',
            'Flute I-II', 
            'Oboe I-II',
            'Bb Clarinets I-II',
            'Bassoons I-II',
            'F Horns I-IV',
            'C Trumpets I-III',
            'Trombone I-II',
            'Bass Trombone',
            'Tuba',
            'Timpani',
            'Percussion',
            'Harp',
            'Violin I',
            'Violin II', 
            'Viola',
            'Violoncello',
            'Contrabass'
        ],
        programNotes: 
            'This piece focuses on one of nature’s most captivating elements—water. “流” is the Chinese character for “flow,” symbolizing water’s fluidity, adaptability, and ceaseless movement. To convey this “flow,” this piece creates the imagery of the tranquil meandering of a river, the cascading rush of a waterfall, and the commanding, unyielding power of the ocean’s tide.',
        videoLink: 'https://www.youtube.com/embed/TUsLd1-g23w?si=qXafk0jXMtS1PvEh',
        premiere: '6/1/2023 - Bing Concert Hall, Stanford CA - Stanford Symphony Orchestra (reading session)'
    }],
])  
    
export default Orchestral;