import React from "react";

import { Box, Typography, } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* Main */
const NotFound = () => {
    const theme = useTheme();
    const styles = {
        container: {
            margin: theme.spacing(4)
        },
    }

    return (
    <Box sx={styles.container}>
        <Box sx={{paddingY: theme.spacing(12)}}>
            <Typography variant="h1" color="error" sx={{ textAlign: 'center', }} gutterBottom>
                404
            </Typography>
            <Typography variant="h4" color="error" sx={{ textAlign: 'center', }}>
                Sorry, the requested URL was not found on this server.
            </Typography>
        </Box>
    </Box>
    )
};

export default NotFound;