import ChamberMusic from './chamberMusic';
import Electroacoustic from './electroacoustic';
import Orchestral from './orchestral';

const Compositions = {
    orchestral : Orchestral,
    chamber: ChamberMusic,
    ea: Electroacoustic,
}

export default Compositions;